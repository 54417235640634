import { AreaType, Module, MongoAttributes } from '..';
type UserPermissions = {
  app: string;
  desktop: string;
};

export type UserPlan = {
  _id: string;
  area: string;
  current_contest: string;
  last_used: boolean;
  remaining_days: number;
  renew: boolean;
  terms_accepted: boolean;
  user_email: string;
  user_level: string;
  user_permissions: UserPermissions;
};

export interface UserContest extends MongoAttributes {
  alpha: number;
  area: string;
  beta: number;
  degree: string;
  discursive_lookup: unknown;
  institution: string;
  jury: string;
  m: number;
  material_lookup: unknown;
  pu: number;
  total_number_question: number;
  test: TestContest;
  total_points: number;
  methodology_lookup: {
    tags: string[] | Record<string, unknown>;
  };
  question_lookup: unknown;
  mkt: unknown;
  number_of_alternatives: number[];
  showing: boolean;
  subjects: Subject[];
}

export type TestContest = {
  year: number;
  name: string;
  duration: number;
};

export type SubjectWithTopics = {
  subject: string;
  topics: string[];
};

export type Subject = {
  evaluation: boolean;
  number_of_questions: number;
  redirect_showing: boolean;
  subject: string;
  weight: number;
  topics: Topics[];
};

export type Topics = {
  has_discursive: boolean;
  has_material: boolean;
  has_questions: boolean;
  name: string;
  position: number;
  redirect_showing: boolean;
};

export type UserInfo = {
  avatar?: string;
  birthdate?: string;
  consulting?: boolean;
  cpf?: string;
  gender?: string;
  name?: string;
  surname?: string;
  phone?: string;
};
export interface User extends MongoAttributes {
  activated: boolean;
  area: AreaType;
  plan: UserPlan;
  contest: UserContest;
  comment: string;
  current_contest: string;
  email: string;
  info: UserInfo;
  modules: Module[];
  permission: {
    group: {
      slug: string;
    };
  };
}

export type UserRequestHeader = {
  headers: {
    'If-Match': string;
    pswdtk?: string;
  };
};

export type UserUpdateResponse = {
  data: {
    _etag: string;
  };
};

type GENDER_TYPE = {
  label: string;
  value: string;
};

export const GENDER_OPTIONS: GENDER_TYPE[] = [
  {
    label: 'Perfiro não mencionar',
    value: 'N',
  },
  {
    label: 'Masculino',
    value: 'M',
  },
  {
    label: 'Femenino',
    value: 'F',
  },
];

export interface UserJourney extends MongoAttributes {
  user_email: string;
  area: string;
  change_contest: boolean;
  evaluation: boolean;
  study_control: boolean;
  objectives: boolean;
  discursives: boolean;
  materials: boolean;
  simulations: boolean;
  statistics: boolean;
  review: boolean;
  quiz: boolean;
}
