import { boot } from 'quasar/wrappers';
import { DirectiveBinding } from 'vue';
import ga from './analytics';
import { FINISH_CATEGORY, FINISH_ACTION } from '../types/GoogleAnalyticsEvents';

export default boot(({ app }) => {
  app.directive('track-mount', {
    beforeMount(el: HTMLDivElement, binding: { value: string }) {
      console.info('TRACK MOUNT >> ', binding.value);
    },
  });
  app.directive('track-click', {
    beforeMount(el: HTMLDivElement, binding: DirectiveBinding<string>) {
      el.onclick = () => {
        console.info('TRACK CLICK >> ', binding.value);
        let category = '';
        if (FINISH_CATEGORY.includes(binding.value)) {
          category = FINISH_ACTION as string;
        }
        ga.logEvent(category, 'click', binding.value);
      };
    },
  });
});
