export enum Messages {
  SESSION_EXPIRED = 'Sua sessão expirou, por favor, faça o login e tente novamente.',
  WRONG_PASSWORD = 'Usuário ou senha incorreta',
  LOGIN_SUCCESS = 'Login realizado com sucesso',
  LOGOUT_SUCCESS = 'Logout realizado com sucesso',
  LOGOUT_FAIL = 'Ocorreu um erro ao tentar fazer logout',
  USER_NOT_FOUND = 'Usuário não encontrado',
  CREATE_QUESTION_HISTORY_ERROR = 'Ocorreu um erro ao tentar criar o histórico de questão',
  ERRO_CREATE_USER = 'Ocorreu um erro ao tentar criar o usuário',
  CREATE_USER_SUCCESS = 'Usuário criado com sucesso',
  UPDATE_MATERIAL_HISTORY_ERROR = 'Ocorreu um erro ao tentar atualizar o material',
  UPDATE_MATERIAL_HISTORY_SUCCESS = 'Material atualizado com sucesso',
  UPDATE_USER_PROFILE_SUCESS = 'Usuário atualizado com sucesso',
  UPDATE_USER_PROFILE_ERROR = 'Ocorreu um erro ao tentar atualizar o usuário',
  FETCH_MATERIAL_ERROR = 'Ocorreu um erro ao tentar buscar o material',
  FETCH_MATERIAL_HISTORY_ERROR = 'Ocorreu um erro ao tentar buscar o histórico do material',
  COUNT_MATERIAL_HISTORY_ERROR = 'Ocorreu um erro ao tentar contar o histórico do material',
  FETCH_EVALUATIONS_HISTORY_ERROR = 'Ocorreu um erro ao tentar buscar o histórico de avaliações',
  FETCH_EVAULATIONS_HISTORY_SUCCESS = 'Histórico de avaliações buscado com sucesso',
  FETCH_LOCAL_CONTEST_SUBJECTS_ERROR = 'Ocorreu um erro ao tentar buscar as disciplinas do edital',
  DEFAULT_ERROR = 'Ocorreu um erro inesperado',
  UPDATE_QUESTION_ERROR = 'Ocorreu um erro ao tentar salvar a questão',
  FETCH_QUESTIONS_ERROR = 'Ocorreu um erro ao buscar as questões',
  FETCH_TOPICS_ERROR = 'Ocorreu um erro ao tentar buscar os tópicos do edital',
  UPDATE_QUESTION_HISTORY_SUCCESS = 'Questão salva no histórico com sucesso',
  UPDATE_QUESTION_HISTORY_ERROR = 'Ocorreu um erro ao tentar atualizar o historico de questão',
  FETCH_QUESTIONS_HISTORY_ERROR = 'Ocorreu um erro ao tentar buscar o histórico de questões',
  FETCH_INFO_YEAR_ERROR = 'Ocorreu um erro ao tentar buscar os anos das questões do edital',
  FETCH_BEST_QUESTION_ERROR = 'Ocorreu um erro ao tentar buscar a próxima questão',
  RESET_PASSWORD_EMAIL_SUCCESS_TITLE = 'Email enviado',
  RESET_PASSWORD_EMAIL_SUCCESS_MESSAGE = 'Um email foi enviado para você com instruções para resetar sua senha',
  RESET_PASSWORD_EMAIL_ERROR_TITLE = 'Email não enviado',
  RESET_PASSWORD_EMAIL_ERROR_MESSAGE = 'Não foi possível enviar um email para você com instruções para resetar sua senha. Verifique se está usando o mesmo email usado no cadastro.',
  SEND_RESET_PASSWORD_EMAIL_TITLE = 'Redefinir Senha',
  SEND_RESET_PASSWORD_MAIL_TEXT = 'Informe o email usado em seu cadastro para que possamos enviar um link para redefinir sua senha.',
  EMAIL_INPUT_ERROR = 'Digite um email válido',
  PASSWORD_CHANGED_SUCCESSFULLY_TITLE = 'Senha alterada',
  PASSWORD_CHANGED_SUCCESSFULLY_MESSAGE = 'Sua senha foi alterada com sucesso',
  PASSWORD_CHANGED_ERROR_TITLE = 'Erro ao alterar a senha',
  PASSWORD_CHANGED_ERROR_MESSAGE = 'Não foi possível alterar a senha. Tente novamente mais tarde.',
  FETCH_STUDIES_ERROR = 'Ocorreu um erro ao tentar buscar seus controles de estudos',
  SAVE_STUDY_ERROR = 'Ocorreu um erro ao tentar salvar seu controle de estudos',
  PAUSE_STUDY_ERROR = 'Ocorreu um erro ao tentar pausar seu controle de estudos',
  UPDATE_OBJECTIVE_GRAPH = 'Gráfico atualizado!',
  INTEGRATION_LOGIN_ERROR = 'Token inválido ou expirado, entre em contato com o suporte ou tente fazer login manualmente.',
  GENERIC_LOGIN_ERROR = 'Credenciais inválidas! Verifique se seu usuário e senha estão corretos!',
  QUIZ_FETCH_QUESTIONS_ERROR = 'Ocorreu um erro ao tentar buscar as questões.',
  QUIZ_FETCH_ERROR = 'Ocorreu um erro ao tentar buscar a prova.',
  QUIZ_HAS_HISTORY = 'Oops... Parece que você já respondeu esta prova!',
  QUIZ_FETCH_HISTORY_ERROR = 'Ocorreu um erro ao tentar buscar os históricos de provas, tente novamente mais tarde.',
  QUIZ_SAVE_HISTORY_ERROR = 'Ocorreu um erro ao tentar salvar o histórico da prova.',
  QUIZ_BUILD_HISTORY_ERROR = 'Ocorreu um erro ao gerar o histórico de questões do usuário.',
  QUIZ_SAVE_QUESTION_ANSWER_ERROR = 'Ocorreu um erro ao tentar salvar a resposta da questão, tente novamente.',
  QUIZ_UPDATE_DURATION = 'Ocorreu um erro ao tentar atualizar a duração da prova.',
  FETCH_CONTESTS_ERROR = 'Ocorreu um erro ao tentar buscar os editais',
  CHANGE_CONTEST_ERROR = 'Ocorreu um erro ao tentar alterar o edital',
  CHANGE_CONTEST_SUCCESS = 'Edital alterado com sucesso',
  SIMULATION_GENERATE_ERROR = 'Ocorreu um erro ao tentar gerar o simulado',
}

export type MessageString = keyof typeof Messages;
