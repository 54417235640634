import { MongoAttributes } from '..';

export interface Objective extends MongoAttributes {
  qid: string;
  info: ObjectiveInfo;
  enunciation: string;
  text_associated: string;
  bestcomment: string;
  subject: string[];
  topic: string[];
  alternatives: Alternatives;
  correct_answer: string;
  reviewed: boolean;
  showing: boolean;
  b: number;
  history_marked_alternative?: string;
}

export interface FetchObjectivesParams {
  where?: ObjectiveWhere;
  page?: number;
  max?: number;
  history?: number;
  sortBy?: [string, string][] | undefined | string;
}
export interface ObjectiveWhere extends MongoAttributes {
  qid?: string | { $in: string[] | undefined };
  info?: ObjectiveInfo;
  enunciation?: string;
  text_associated?: string;
  bestcomment?: string;
  subject?: string[];
  topic?: string[];
  alternatives?: Alternatives;
  correct_answer?: string;
  reviewed?: boolean;
  showing?: boolean;
  b?: number;
}

export interface ObjectiveHistoryWhere extends MongoAttributes {
  marked_alternative?: string | Record<string, unknown>;
  origin?: string;
  origin_id?: string;
  qid?: string | string[] | { $in: string[] };
  scored?: boolean;
  subject?: string[] | { $in: string[] };
  time_spent?: number;
  topics?: string[] | { $in: string[] };
  user_email?: string;
}

export type SetTabFn = ((a: string) => void) | undefined;
export type ClockFn = (() => void) | undefined;

export type SaveHistoryParams = {
  history?: ObjectiveHistoryWhere;
  origin?: string;
  origin_id?: string;
  objective?: Objective | undefined;
  answer?: string | undefined;
  correct?: boolean | undefined;
  time?: number;
};

export type FetchHistoryParams = {
  where: ObjectiveHistoryWhere;
  page?: number;
  max?: number;
  sort?: string;
  headers?: Record<string, unknown>;
  projection?: Record<string, number>;
};
export interface ObjectiveHistory extends MongoAttributes {
  user_email?: string;
  scored: boolean;
  subject: string[];
  topics: string[];
  time_spent: number;
  origin: string;
  origin_id: string;
  marked_alternative: string;
  qid: string;
}

export type UpdatedQuestionHistory = {
  data: {
    _etag: string;
    _id: string;
    _updated: string;
    _created: string;
    _status: string;
  };
};

export type PostMultipleResponse = {
  _etag: string;
  _id: string;
  _updated: string;
  _created: string;
  _status: string;
};

export interface PostMultipleHistories {
  _items: PostMultipleResponse[];
  _status: string;
}

export interface ObjectiveHistoryStore extends MongoAttributes {
  subject: string[];
  scored: boolean;
}

export type Alternative = {
  a?: string;
  b?: string;
  c?: string;
  d?: string;
  e?: string;
};

type Alternatives = {
  a: string;
  b: string;
  c?: string;
  d?: string;
  e?: string;
};

export type ObjectiveFilter = {
  subject?: Record<string, unknown>;
  topic?: Record<string, unknown>;
  info?: Record<string, unknown>;
  b?: Record<string, unknown>;
};

export type ObjectiveInfo = {
  institution?: string;
  year?: number | Record<string, unknown>;
  jury?: string | Record<string, unknown>;
  test_name?: string[];
};

export const HISTORY_ALL = {
  label: 'Todas',
  value: 0,
};

export const HISTORY_NOTRESOLVED = {
  label: 'Não Resolvidas',
  value: 1,
};

export const HISTORY_RESOLVED = {
  label: 'Resolvidas',
  value: 2,
};

export const HISTORY_WRONG = {
  label: 'Erradas',
  value: 3,
};

export const HISTORY_CORRECT = {
  label: 'Acertadas',
  value: 4,
};

export const HISTORY = [
  HISTORY_ALL,
  HISTORY_NOTRESOLVED,
  HISTORY_RESOLVED,
  HISTORY_WRONG,
  HISTORY_CORRECT,
];
