import { LoginResponseObject } from './../types/Auth';
import { defineStore } from 'pinia';

export const useUser = defineStore('main', {
  state: () => ({
    user: null as LoginResponseObject | null,
  }),
  getters: {
    loggedIn(): boolean {
      return !!this.user;
    },
  },
  actions: {},
});
