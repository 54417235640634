import * as Sentry from '@sentry/browser';
const LOGGER = process.env.LOGGER === 'true';

if (LOGGER) {
  console.log('%c Logging error is enabled.', 'color: orange');
}

export type Level = 'error' | 'warn' | 'info' | 'debug' | 'trace';

export class Logger {
  private module: string;

  constructor(module: string) {
    this.module = module;
  }

  public log(logLevel: Level, message: string, ...args: unknown[]): void {
    const logColor = {
      error: 'red',
      warn: 'yellow',
      info: 'green',
      debug: 'cyan',
      trace: 'gray',
    };
    if (LOGGER) {
      console.log(
        `%c[${this.module}] %c${message}`,
        'color: orange',
        `color: ${logColor[logLevel]}`
      );
      console[logLevel](`%c [${this.module}]`, 'color: orange', ...args);
    }
    Sentry.captureMessage(message);
  }

  public error(message: string, ...args: unknown[]): void {
    this.log('error', message, ...args);
  }

  public warn(message: string, ...args: unknown[]): void {
    this.log('warn', message, ...args);
  }

  public info(message: string, ...args: unknown[]): void {
    this.log('info', message, ...args);
  }

  public debug(message: string, ...args: unknown[]): void {
    this.log('debug', message, ...args);
  }

  public trace(message: string, ...args: unknown[]): void {
    this.log('trace', message, ...args);
  }
}
