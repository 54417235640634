/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */

class Mongo {
  public endpoint: string;
  constructor() {
    this.endpoint = '';
    this.flattenMongoQuery = this.flattenMongoQuery.bind(this);
    this.removeMongoAttrs = this.removeMongoAttrs.bind(this);
  }
  private isUnfunctionalKey(key: string): boolean {
    return ![
      '$in',
      '$nin',
      '$lt',
      '$lte',
      '$gt',
      '$gte',
      '$ne',
      '$eq',
      '$exists',
    ].includes(key);
  }
  public flattenMongoQuery({
    query = {},
    path,
  }: {
    query: any;
    path?: string;
  }): any {
    return Object.keys(query).reduce((acc, key) => {
      const value = query[key];
      if (this.isUnfunctionalKey(key)) {
        const newPath = path ? `${path}.${key}` : key;
        if (typeof value === 'object' && value !== null) {
          return {
            ...acc,
            ...this.flattenMongoQuery({ query: value, path: newPath }),
          };
        }
        return { ...acc, [newPath]: value };
      } else {
        return {
          ...acc,
          [path as string]: { ...acc[path as keyof Object], [key]: value },
        };
      }
    }, {});
  }
  public removeMongoAttrs(obj: any): any {
    for (const key in obj) {
      if (key.startsWith('_') || obj[key] === null) {
        delete obj[key];
      }
      if (typeof obj[key] === 'object') {
        this.removeMongoAttrs(obj[key]);
      }
    }
    return obj;
  }
  public parseSortArr(sortList: [string, string][] | undefined) {
    if (!sortList) return '';
    const fields = [];
    for (const sort of sortList) {
      fields.push(`("${sort[0]}", ${sort[1] === 'desc' ? -1 : 1})`);
    }
    return `[${fields.join(', ')}]`;
  }
}

export default Mongo;
