
import { AxiosError } from 'axios';
import { defineComponent } from 'vue';
import Auth from './boot/auth';
import { api } from './boot/axios';
import Authentication from './boot/services/Authentication';
import { useUser } from './pinia/useUser';

export default defineComponent({
  name: 'App',
  setup() {
    const userStore = useUser();
    const user = Authentication.loggedUser();
    if (user) userStore.$state.user = user;
    api.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        if (error.response?.status === 401) {
          Auth.logout();
          userStore.$state.user = null;
        }
        return Promise.reject(error);
      }
    );
  },
});
