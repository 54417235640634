export type Meta = {
  max_results: number;
  page: number;
  total: number;
};

export type Links = {
  last: LinkData;
  next: LinkData;
  parent: LinkData;
  prev: LinkData;
  self: LinkData;
};

type LinkData = {
  href: string;
  title: string;
};

export type Eve = {
  _links: Links;
  _meta: Meta;
};

export interface EveResponse<T> {
  _items: T;
  _links: Links;
  _meta: Meta;
}

export type EveWhere<T> = {
  where?: T;
  page?: number;
  max?: number;
  sortBy?: [string, string][];
};

export type EveUpdate = {
  _etag?: string;
  _id?: string;
  _updated?: string;
  _created?: string;
  _status?: string;
};

export class NoAuthError extends Error {
  constructor(m: string) {
    super(m);
  }
}
export interface QueryParams {
  where?: Record<string, unknown>;
  page?: number;
  max?: number;
  sort?: Record<string, unknown> | [string, string][] | string; 
}

export interface headers {
  'x-total-count': number;
}

export interface TouchPanType {
  touch: boolean;
  mouse: boolean;
  position: {
    top: number;
    left: number;
  };
  direction: string;
  isFirst: boolean;
  isFinal: boolean;
  duration: number;
  distance: {
    x: number;
    y: number;
  };
  offset: {
    x: number;
    y: number;
  };
  delta: {
    x: number;
    y: number;
  };
}
