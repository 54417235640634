import { Notify } from 'quasar';

class NotifyHelper {
  public static notifySuccess = (message: string, timeout = 3000) => {
    Notify.create({ message, type: 'positive', timeout });
  };

  public static notifyError = (message: string,timeout = 3000) => {
    Notify.create({ message, type: 'negative', timeout });
  };
}

export default NotifyHelper;
