import { Links, Meta, MongoAttributes } from '..';
export interface Cycle extends MongoAttributes {
  availability: Availability[];
  eid: string;
  subject: string;
  theta: number;
  user_email: string;
  links: Links;
}

export interface CycleData extends MongoAttributes {
  user_email?: string;
  schedule_id: string;
  availability: Availability[];
  subjects: SubjectCycle[];
  cycle: CycleUpdate[];
  week: number;
  duration: number;
  week_start: DaysOfWeekKeys;
}

export type SubjectCycle = {
  subject: string;
  pomodoros: number;
  practice: number;
  relevance?: number;
  review: number;
  theory: number;
};

export type CycleResponse = {
  data: CycleEveResponse;
};

export type CycleWhere = {
  _id?: string;
  eid?: string;
  subject?: string[];
  user_email?: string;
};

type CycleEveResponse = {
  _items: CycleData[];
  _links: Links;
  _meta: Meta;
};

export type CycleSuggestedResponse = {
  data: {
    subjects: Subjects[];
  };
};

export type CycleDailySuggestedResponse = {
  data: DailySubjects[];
};

export type DailySubjects = {
  subject: string;
  pomodoros: number;
};

type Subjects = {
  subject: string;
};

export type CycleParams = {
  where?: CycleWhere;
  page?: number;
};

export type SuggestedSubjectsParams = {
  schedule_id: string;
  paused?: boolean;
  availability?: Availability[];
};

export type Availability = {
  week_day: DaysOfWeekKeys;
  minutes: number;
};

export type CycleUpdate = {
  week_day: DaysOfWeekKeys;
  goal: number;
  studied: {
    subject: string;
    pomodoros: number;
  }[];
};

export type SubjectCycleResume = {
  subject: string;
  theory: number;
  practice: number;
  review: number;
  completed: number;
  pomodoros: number;
};

export enum DaysOfWeek {
  sunday = 'sunday',
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
}

export type DaysOfWeekKeys = keyof typeof DaysOfWeek;
