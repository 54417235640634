import { boot } from 'quasar/wrappers';
import axios, { AxiosInstance } from 'axios';

const AUTH_API_URL = process.env.AUTH_API_URL
const AUTH_API_V2_URL = process.env.AUTH_API_V2_URL
const GENERAL_API_URL = process.env.GENERAL_API_URL
const REGISTER_API_URL= process.env.REGISTER_API_URL

const token = 'YWRtaW46YWRtaW4=';

const headers = {
  'Content-Type': 'application/json',
};

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
  }
}

const registerApi = axios.create({
  baseURL: REGISTER_API_URL,
  headers,
})

const IpInfoApi = axios.create({
  baseURL: 'https://ipinfo.io',
})

const publicApi = axios.create({
  baseURL: AUTH_API_URL,
  headers,
});

const authApi = axios.create({
  baseURL: AUTH_API_V2_URL,
  headers,
});

const api = axios.create({
  baseURL: GENERAL_API_URL,
  headers: {
    ...headers,
    Authorization: `Basic ${token}`,
  },
});

export default boot(({ app }) => {
  app.config.globalProperties.$axios = axios;
  app.config.globalProperties.$publicApi = publicApi;
  app.config.globalProperties.$api = api;
});

export { publicApi, api, authApi, registerApi, IpInfoApi };
