/* eslint-disable @typescript-eslint/no-explicit-any */
import { authApi } from '../axios';
import { api } from '../axios';
import Mongo from './Mongo';
import { SessionStorage } from 'quasar';
import { AreaType, TermsUse } from '../../types';
import { AxiosResponse } from 'axios';
import { Logger } from '../Logger';

const AREA_DISCOVER_ENDPOINT = '/area';
const AREA_AGREEMENT_ENDPOINT = '/agreement';
const logger = new Logger('Area');
class Area extends Mongo {
  constructor() {
    super();
    this.endpoint = 'area';
  }
  public async discover(): Promise<AreaType> {
    const windowURL = window.location.hostname;

    const response: { data: AreaType } = await authApi.get(
      `${AREA_DISCOVER_ENDPOINT}/${windowURL}`
    );
    SessionStorage.set('area', response.data);

    return response.data;
  }
  public loggedArea(): AreaType {
    return SessionStorage.getItem('area') as AreaType;
  }
  public async getAgreement(): Promise<AxiosResponse<TermsUse> | undefined> {
    try {
      const area = this.loggedArea().name;
      if (!area) return;

      const response: AxiosResponse<TermsUse> = await api.get(
        `${AREA_AGREEMENT_ENDPOINT}/${area}`
      );

      return response;
    } catch (err) {
      logger.error('Error on try get area', err);
    }
  }
}

export default new Area();
