import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';

export enum Roles {
  MATERIAL_MODULE = 'material-teorico',
  FIRST_STEP_MODULE = 'primeira-fase',
  OBJECTIVE_MODULE = 'questoes-objetivas',
}

export type RoleFilter = {
  slugs?: string[];
  paths?: string[];
  router?: {
    to: RouteLocationNormalized;
    next: NavigationGuardNext;
  };
};

export type RoleString = keyof typeof Roles;
