import { api, authApi } from '../axios';
import Mongo from './Mongo';

import {
  UserRequestHeader,
  UserUpdateResponse,
  User as IUser,
  MongoAttributes,
  LoginResponseObject,
} from '../../types';

import Authentication from './Authentication';
import { AxiosResponse } from 'axios';

class User extends Mongo {
  public async getById(id: string): Promise<IUser> {
    const response = await api.get<IUser>(`/user/${id}`);
    return response.data;
  }

  public async activateUser(
    id: string,
    etag: string
  ): Promise<MongoAttributes> {
    const headers = { 'If-Match': etag };
    const response: AxiosResponse<MongoAttributes> = await api.patch(
      `/user/${id}`,
      { activated: true },
      { headers }
    );
    return response.data;
  }

  public async resetPassword(id: string, password?: string) {
    const headers: {
      Authorization: string;
    } = {
      Authorization: 'Bearer YWRtaW46YWRtaW4=',
    };
    const user = await this.getById(id);
    const { email } = user;
    await authApi.patch(
      '/user/reset-password',
      { email, password },
      { headers }
    );
  }

  public async updateUser(
    user: LoginResponseObject,
    password?: string
  ): Promise<void> {
    if (!user) return;
    const userId = user.user._id as string;
    const etag = user.user._etag as string;
    const config: UserRequestHeader = {
      headers: { 'If-Match': etag },
    };
    if (password) config.headers.pswdtk = password;

    const response: UserUpdateResponse = await api.patch(
      `/user/admin/${userId}`,
      { info: user.user.info },
      config
    );

    user.user._etag = response.data._etag;
    Authentication.storeUser(user);
  }
}

export default new User();
