export type MongoAttributes = {
  _created?: string | Record<string, unknown>;
  _etag?: string;
  _id?: string;
  _updated?: string | Record<string, unknown>;
  _deleted?: boolean;
};

export type FetchManyParams = {
  where: Record<string, unknown>;
  page?: number;
  max_results?: number;
  projection?: Record<string, unknown>;
  sortBy?: [string, string][];
  headers?: Record<string, unknown>;
};
export type FetchOneParams = {
  query: string;
  headers?: Record<string, unknown>;
};
export type UpdateParams = {
  query?: string;
  object: Record<string, unknown>;
  headers?: Record<string, unknown>;
};

//AREA
export const AREA_DISCOVER_ENDPOINT = '/area';
export const AREA_AGREEMENT_ENDPOINT = '/agreement';
//OBJECTIVES ENDPOINTS
export const QUESTIONS_ENDPOINT = '/questions';
export const QUESTIONS_HISTORY_ENDPOINT = '/history/question';
export const QUESTIONS_YEAR_ENDPOINT = '/service/distinct/questions/info.year';
//DISCURSIVE ENDPOINTS
export const DISCURSIVE_ENDPOINT = '/discursive';
export const DISCURSIVE_HISTORY_ENDPOINT = '/discursive/history';
export const DISCURSIVE_SUBJECT_ENDPOINT =
  '/service/distinct/discursive/subject';
export const DISCURSIVE_YEAR_ENDPOINT =
  '/service/distinct/discursive/info.year';
//EVALUATIONS ENDPOINTS
export const HISTORY_EVALUATION_ENDPOINT = '/history/evaluation';
export const BEST_QUESTION_ENDPOINT = 'questions/best';
//MATERIALS ENDPOINT
export const MATERIAL_ENDPOINT = '/material';
export const HISTORY_END_POINT = '/history';

export const endpoints = {
  AREA: {
    AREA_DISCOVER_ENDPOINT,
    AREA_AGREEMENT_ENDPOINT,
  },
  DISCURSIVES: {
    DISCURSIVE_ENDPOINT,
    DISCURSIVE_HISTORY_ENDPOINT,
    DISCURSIVE_SUBJECT_ENDPOINT,
    DISCURSIVE_YEAR_ENDPOINT,
  },
  EVALUATIONS: {
    HISTORY_EVALUATION_ENDPOINT,
    BEST_QUESTION_ENDPOINT,
  },
  MATERIALS: {
    MATERIAL_ENDPOINT,
    HISTORY_END_POINT,
  },
  OBJECTIVES: {
    QUESTIONS_ENDPOINT,
    QUESTIONS_HISTORY_ENDPOINT,
    QUESTIONS_YEAR_ENDPOINT,
  },
};
